.css-yk16xz-control {
    border: none !important;
}

.header-right .header-profile .nav-link {
    border: none;
}

.picture-container {
    position: relative;
    cursor: pointer;
    text-align: center;
}

.picture {
    width: 106px;
    height: 106px;
    background-color: #999999;
    border: 4px solid #CCCCCC;
    color: #FFFFFF;
    border-radius: 50%;
    margin: 0px auto;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

.picture:hover {
    border-color: #2ca8ff;
}

.content.ct-wizard-green .picture:hover {
    border-color: #05ae0e;
}

.content.ct-wizard-blue .picture:hover {
    border-color: #3472f7;
}

.content.ct-wizard-orange .picture:hover {
    border-color: #ff9500;
}

.content.ct-wizard-red .picture:hover {
    border-color: #ff3b30;
}

.picture input[type="file"] {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0 !important;
    position: absolute;
    top: 0;
    width: 100%;
}

.picture-src {
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .table-responsive::-webkit-scrollbar {
    display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .table-responsive {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
} */

.dataTables_wrapper .dataTables_paginate>.paginate_button {
    padding: 0.5rem 1rem !important;
}

.form-control {
    border-color: #8d8d8d;
}

input:read-only {
    background-color: #f2f2f2 !important;
}

.error {
    color: red;
}

.gradient-custom {
    /* fallback for old browsers */
    background: #ffffff;

    /* Chrome 10-25, Safari 5.1-6 */
    /* background: -webkit-linear-gradient(315deg, #000000 0%, #414141 100%); */

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background: linear-gradient(315deg, #000000 0%, #414141 100%) */
}

.gradient-custom .card {
    background-color: transparent;
    border: 2px solid #8d8d8d;
}

.text-gold {
    color: #000000;
}

.btn-gold:hover,
.btn-gold:active {
    color: #f2f2f2 !important;
    background-color: #000000;
    border-color: #000000;
}

.btn-gold {
    background-color: #000000 !important;
    border-color: #000000;
}

a:hover {
    color: #000000;
}

.gradient-custom .border-gold {
    background-color: transparent;
    border: 2px solid #8d8d8d;
    border-radius: 10px;
}

.thumbnail {
    width: 100px;
    height: auto;
}

.box-shadow-light {
    box-shadow: 5px 5px 10px 0px #c4c4c4;
}

.icon-action {
    cursor: pointer;
}

.icon-action.remove {
    color: var(--bs-red);
}

.dashboard-data-icon {
    font-size: x-large;
    position: absolute;
}

.card-announcement .card-body {
    max-height: 60vh;
    overflow-y: auto;
}

.card-announcement .card-body>.card-item:not(:last-child) {
    border-bottom: 1px solid #F2F2F2 !important
}

.icon-transistion .zoom-out {
    /* transition:all 1s ease-in;
    -webkit-transition:all 1s ease-in;
    -moz-transition:all 1s ease-in;
    -o-transition:all 1s ease-in; */
    /* transform: scale(2);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2); */
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 4s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 4s;
    -webkit-animation-name: grow;
    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: grow;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    transition-property: -moz-transform;
    transition-duration: 4s;
    animation-name: grow;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* 
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
    -webkit-animation-name: shrink;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: shrink;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    transition-property: -moz-transform;
    transition-duration: 1s;
    animation-name: shrink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; */
}

@-webkit-keyframes grow {
    0% {
        -webkit-transform: scale(1.25);
    }

    50% {
        -webkit-transform: scale(2.5);
    }

    100% {
        -webkit-transform: scale(1.25);
    }
}

@-moz-keyframes grow {
    0% {
        -webkit-transform: scale(1.25);
    }

    50% {
        -webkit-transform: scale(2.5);
    }

    100% {
        -webkit-transform: scale(1.25);
    }
}

@keyframes grow {
    0% {
        -webkit-transform: scale(1.25);
    }

    50% {
        -webkit-transform: scale(2.5);
    }

    100% {
        -webkit-transform: scale(1.25);
    }
}

@media screen and (max-width: 480px) {
    .table-responsive .table.dataTable.display {
        min-height: 30vh !important;
    }

    .d-mobile-none {
        display: none;
    }
}

.text-toggle-fee:hover {
    color: var(--primary);
    cursor: pointer;
}

.text-toggle-fee i {
    font-size: smaller;
}

.status-box {
    border: 1px solid white;
    border-radius: 0.5em;
    padding: 5px 7px;
}

.status-box.pending {
    border-color: var(--bs-warning);
    background-color: var(--bs-warning);
    color: white;
}

.status-box.shipped {
    border-color: var(--bs-cyan);
    background-color: var(--bs-cyan);
    color: white;
}

.status-box.completed {
    border-color: var(--bs-green);
    background-color: var(--bs-green);
    color: white;
}

.status-box.investment {
    border-color: var(--bs-info);
    background-color: var(--bs-info);
    color: white;
}


.status-box.active {
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
    color: white;
}

.status-box.cancelled,
.status-box.rejected {
    border-color: var(--bs-red);
    background-color: var(--bs-red);
    color: white;
}

.status-box.completed {
    border-color: var(--bs-green);
    background-color: var(--bs-green);
    color: white;
}

/* input{
    font-size: 14px;
}
input:hover{
    width: 100%;
}
input, select { font-size: 100%; } */
.date-picker-container {
    padding-top: 0;
    padding-bottom: 0;
}

.date-picker-container .MuiInput-underline:before,
.date-picker-container .MuiInput-underline:after {
    content: '' !important;
    border-bottom: none !important;
}

.date-picker-container .MuiFormControl-root {
    background: transparent;
}

.date-picker-container .MuiInputBase-input {
    background: transparent !important;
}

.table-export-button-excel {
    background-color: #009800;
    color: #FFFFFF;
}

.table-export-button-excel:hover {
    background-color: #52d352;
    color: #FFFFFF;
}

.arrow {
    border: solid black;
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 10px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.hierarchy-card {
    min-width: 300px;
}

.blue-hover {
    color: inherit;
    text-decoration: none;
}

.blue-hover:hover {
    color: blue;
}

.nav-header-logo {
    /* background-color: white; */
    /* border-radius: .75rem; */
    /* box-shadow: 10px -10px 60px 0.5rem;   */
    /* box-shadow: 0 0 50px 10px;   */
}

.nav-header-logo-mobile {
    display: none;
    /* background-color: white; */
    /* border-radius: .75rem; */
    /* box-shadow: 10px -10px 60px 0.5rem;   */
    /* box-shadow: 0 0 50px 10px;   */
}

.menu-toggle .nav-header .nav-header-logo {
    display: none;
}

.menu-toggle .nav-header .nav-header-logo-mobile {
    display: block;
}


@media screen and (max-width: 768px) {
    .nav-header-logo {
        display: none;
        box-shadow: none !important;
    }

    .nav-header-logo-mobile {
        display: block;
    }
}